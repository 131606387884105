import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

import './layout.css'

const Layout = ({ children }) => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        nav: allSitePage (
        filter: {
          path: {
            glob: "/*"
            ne: "/dev-404-page/"
            # regex: "/the-original-theatre-of-the-city-of-new-york/(?!volume-.*)/"

          }
        }
        sort: { order:DESC fields: [path] }
      ) {
        edges {
          node {
            path
          }
        }
      }
      }`
    )

  const normalize = str => str.replace(/\//g, '').split('-').map(x => x.slice(0,1).toUpperCase() + x.slice(1)).join(' ')
  return (
    <main className="max-w-5xl px-6 mx-auto text-white">
      <header className="flex justify-between w-full py-3">
        <Link to="/">
          <h1 className="text-4xl">{ data.site.siteMetadata.title }</h1>
        </Link>
      </header>
      <nav className="pt-3">
        {data.nav.edges.map(({ node }) => (
          <Link className="nav-link" to={node.path} key={node.path}>
          {normalize(node.path)}
          </Link>
        ))}
        <a href="https://dlib.nyu.edu/findingaids/html/fales/brecht/">
          The Fales Library Finding Aid
        </a>
      </nav>
      <div className="h-full pt-12 pb-20 border-b border-gray-900">
        { children }
      </div>
    </main>
  )
}

export default Layout
