import React from 'react'
import {Link} from 'gatsby'
import Layout from '../../components/layout'
import poetry from '../../data/poetry/publications.yml'

const { publications } = poetry

export default () => (
  <Layout>
    <h1 className="text-3xl">Poetry</h1>
    <ul>
      <li>

        <Link to="/poetry/selected" className="hover:underline">
          Selectd Poems
        </Link>
      </li>
      <li>
        <Link to="/poetry/recordings" className="hover:underline">
          Recordings of Stefan Brecht's Readings
        </Link>
      </li>
    </ul>

    <h2 className="my-8 text-2xl">Publications</h2>
    <section className="publications-grid">
    {publications.map(p => (
      <div key={p.id}>
        <img src={`/images/${p.cover_image}`} alt={p.title} className="mb-6"/>
        <a href={p.url} className="text-lg">
          Purchase
        </a>
      </div>
    ))}
    </section>
  </Layout>
)
